import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {AuthenticationService, Role, SiteSettings} from '@process-manager/pm-library';
import {jwtDecode} from 'jwt-decode';
import {catchError, map, Observable, ObservableInput, of, Subject} from 'rxjs';
import {environment} from '../../../environments/environment';
import {User} from '../../tabs/processes-tab/shared/model/user';
import {createJsonBasedSelectiveStorage} from "../utils/selective-storage";
import {SettingsService} from './settings.service';

const PM_ISSUER = 'https://sites.process-manager.dk/';

interface LoginServerReply {
  success: boolean;
  token: string;
  refreshToken: string;
  settings: any;
  changePassword: boolean;
}

export interface LoginReply {
  token: string,
  siteSettings: SiteSettings;
  user: User;
  passwordIsTemporary: boolean;
}

type DecodedTokenType = {
  iss?: string,
  pm_user_public?: boolean,
  pm_username?: string,
  preferred_username?: string,
  upn?: string,
  unique_name?: string,
  pm_first_name?: string,
  given_name?: string,
  pm_last_name?: string,
  family_name?: string,
  pm_email?: string,
  pm_groups?: string[] | number[],
  pm_access_node?: number,
  pm_start_node?: number,
  pm_user_language?: string,
  pm_roles?: Role[],
  roles?: string[],
  role?: string[],
  'cognito:groups'?: string[]
};

@Injectable({
  providedIn: 'root',
})
export class LocalLoginService {

  public static readonly AUTH_STORAGE_PREFIX = 'pm_auth_';
  public static readonly LOGIN_ERROR_STRING = 'login.error.user-pass';
  public static readonly LOGIN_ERROR_UNKNOWN = 'login.error.public.ip';

  private currentDomain?: string;
  private userRefreshedSubject = new Subject<void>;
  public userRefreshed$ = this.userRefreshedSubject.asObservable();
  private storage?: Storage;

  setDomain(domain?: string) {
    this.currentDomain = domain;
    if(!!domain) {
      this.storage = createJsonBasedSelectiveStorage(LocalLoginService.AUTH_STORAGE_PREFIX + this.currentDomain);
    } else {
      this.storage = undefined;
    }
  }

  login(domain: string, username: string, password: string): Observable<LoginReply> {
    const options = {headers: new HttpHeaders().set('Content-Type', 'application/json')};

    this.setDomain(domain);
    return this.http
      .post<LoginServerReply>(environment.api + domain.toLowerCase() + '/security/session', JSON.stringify({
        'username': username,
        'password': password,
        'user_is_public': false,
        'token_response': 'body'
      }), options).pipe(
        map(this.convertAndStoreLoginReply()),
        catchError(this.handleError));
  }

  logout(): Observable<void> {
    if(!!this.currentDomain) {
      const domain = this.currentDomain;
      this.storage?.clear();
      this.currentDomain = undefined;
      return this.http.post<void>(`${environment.api}${domain}/security/logout`, null);
    }

    return of();
  }

  refresh = (): Observable<LoginReply> => {
    const headers = new HttpHeaders({
      Authorization: 'Bearer ' + this.storage?.getItem('refreshToken')
    });

    return this.http.post<LoginServerReply>(environment.api + this.currentDomain?.toLowerCase() + '/security/session', {
      token_response: "body"
    }, {headers}).pipe(
      map(this.convertAndStoreLoginReply()),
      catchError(this.handleError));
  };

  private handleError = (err: any): ObservableInput<any> => {
    switch ((err as HttpErrorResponse).status) {
      case 404:
        throw new Error(AuthenticationService.DOMAIN_MISSING_ERROR_STRING);
      case 409:
        throw new Error(AuthenticationService.DOMAIN_DISABLED_ERROR_STRING);
      case 403:
        throw new Error(AuthenticationService.LOGIN_ERROR_PUBLIC_IP_STRING);
      default:
        throw new Error(AuthenticationService.LOGIN_ERROR_STRING);
    }
  };

  private getRoles(decodedToken: DecodedTokenType, siteSettings: SiteSettings): Role[] {

    if (siteSettings.roles.size > 0) {
      const foundRoles = decodedToken.pm_roles || decodedToken.roles || decodedToken.role ||
        decodedToken['cognito:groups'] || [];
      const hasRole = (role: string) => {
        const roleMembers = siteSettings.roles.get(role) || [];
        return roleMembers.length === 0 || roleMembers.filter(x => foundRoles.includes(x)).length > 0;
      };
      return (['admin', 'edit', 'showLinks'] as Role[]).filter(hasRole);
    } else {
      return decodedToken.pm_roles || [] as Role[];
    }
  }

  private convertAndStoreLoginReply = () => (res: LoginServerReply) => {
    if (!res.success) {
      throw new Error(LocalLoginService.LOGIN_ERROR_STRING);
    }

    const siteSettings: SiteSettings = this.settingsService.decodeSettings(res.settings);
    const claims: any = jwtDecode(res.token) as DecodedTokenType;

    this.storage?.setItem('token', res.token);
    this.storage?.setItem('refreshToken', res.refreshToken);
    this.storage?.setItem('claims', JSON.stringify(claims));
    this.storage?.setItem('siteSettings', JSON.stringify(siteSettings));
    const user = this.decodeUser(claims, siteSettings);

    if (!user) {
      throw new Error(LocalLoginService.LOGIN_ERROR_UNKNOWN);
    }

    const convertedReply = {
      token: res.token,
      refreshToken: res.refreshToken,
      claims,
      siteSettings,
      user,
      passwordIsTemporary: res.changePassword,
    };

    this.userRefreshedSubject.next();
    return convertedReply;
  };

  private decodeUser(decodedToken: any, siteSettings?: SiteSettings): User | null {
    if (!decodedToken) {
      return null;
    }

    let roles: Role[] = [];
    let rootId: number | undefined;

    if (!!siteSettings) {
      roles = this.getRoles(decodedToken, siteSettings);
      rootId = siteSettings.rootId;
    }

    return {
      isLocalPMUser: decodedToken.iss === PM_ISSUER,
      userIsPublic: !!decodedToken.pm_user_public,
      username: decodedToken.pm_username || decodedToken.preferred_username || decodedToken.upn ||
        decodedToken.unique_name,
      firstName: decodedToken.pm_first_name || decodedToken.given_name,
      lastName: decodedToken.pm_last_name || decodedToken.family_name,
      email: decodedToken.pm_email || decodedToken.email,
      roles: roles,
      groups: decodedToken.pm_groups || roles,
      accessId: decodedToken.pm_access_node || rootId,
      startId: decodedToken.pm_start_node || decodedToken.pm_access_node || rootId,
      language: decodedToken.pm_user_language && parseInt(decodedToken.pm_user_language),
    };
  }

  public getToken(): string {
    return this.storage?.getItem('token') || '';
  }

  public getClaims(): Record<string, any> {
    return JSON.parse(this.storage?.getItem('claims') || '{}');
  }

  public hasValidTokens() {
    const decodedToken = this.getClaims();
    const date = new Date(0);
    date.setUTCSeconds(decodedToken['exp'] || 0);
    const currentTime = new Date().valueOf();
    const tokenExpiryTime = date.valueOf();
    return tokenExpiryTime > currentTime + 30000;
  }

  constructor(private http: HttpClient, private settingsService: SettingsService) {
  }
}

import {Injectable} from '@angular/core';
import {Platform} from "@ionic/angular";
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {concatLatestFrom} from '@ngrx/operators';
import {Store} from '@ngrx/store';
import {DomainCheckerService, DomainStatus} from '@process-manager/pm-library';
import {of, switchMap, tap} from 'rxjs';
import {catchError, filter, map} from 'rxjs/operators';
import {ReportableError} from "../../model/ReportableError";
import {LoginManagerService} from '../../services/login-manager.service';
import {DomainApiActions, DomainGuardActions} from './domain.actions';
import {selectDomainName} from './domain.selectors';

// noinspection JSUnusedGlobalSymbols
@Injectable()
export class DomainEffects {
  $domainEntered = createEffect(() => {
    return this.actions$.pipe(
      ofType(DomainGuardActions.domainEntered),
      concatLatestFrom(() => this.store.select(selectDomainName)),
      filter(([{domain: requestedDomain} ,loadedDomain]) => {
        console.debug('Filtering for domains: ', requestedDomain, loadedDomain);
        return requestedDomain !== loadedDomain;
      }),
      map(([{domain}]) => {
        console.debug('Loading new domain status (clearing old domain) for domain' , domain);
        return DomainApiActions.loadDomainStatus({domain});
      })
    )
  });

  $domainLeft = createEffect(() => {
    return this.actions$.pipe(
      ofType(DomainGuardActions.domainLeft),
      tap(() => this.loginManager.clearDomain())
    )
  }, {dispatch: false});

  $domainLoadStarted = createEffect(() => {
    return this.actions$.pipe(
      ofType(DomainApiActions.loadDomainStatus),
      switchMap(({domain}) => {
        if(!domain) {
          console.warn('No domain in route');
          return of(DomainApiActions.loadDomainStatusFailure({error: new ReportableError('Route does not contain a domain', 'error.unknown')}));
        }

        return this.domainChecker.getDomainStatus(domain).pipe(map((domainStatus: DomainStatus) => {
          if(domainStatus.type === 'oidc' && this.platform.is('ios')) {
            return DomainApiActions.loadDomainStatusFailure({error: new ReportableError('Cannot use SSO on iOS', 'domain.error.sso-on-ios')});
          }
          return DomainApiActions.loadDomainStatusSuccess({domainStatus});
        }), catchError((error) => {
          console.warn('Domain loading failed', domain, error);
              return of(DomainApiActions.loadDomainStatusFailure({error: new ReportableError(error, error.status === 404 ? 'domain.error.missing' : 'domain.error.load-error')}));
        }));
      })
    )
  });

  $loadDomainSuccess = createEffect(() => {
    return this.actions$.pipe(
      ofType(DomainApiActions.loadDomainStatusSuccess),
      tap(({domainStatus}) => {
        this.loginManager.setDomain(domainStatus);
      }),
    )
  }, {dispatch: false})

  $loadDomainFailure = createEffect(() => {
    return this.actions$.pipe(
      ofType(DomainApiActions.loadDomainStatusFailure),
      tap(() => this.loginManager.clearDomain())
    )
  }, {dispatch: false})

  constructor(private actions$: Actions, private domainChecker: DomainCheckerService, private loginManager: LoginManagerService, private store:Store, private platform: Platform) {}}

import {inject} from '@angular/core';
import {CanActivateFn, Router} from '@angular/router';
import {LoginManagerService} from "../services/login-manager.service";

export const PreselectedDomainGuard: CanActivateFn = () => {
  const router = inject(Router);
  const loginManager = inject(LoginManagerService)
  const currentDomain = loginManager.getCurrentDomain();
  if(!currentDomain) {
    return true;
  }

  // Delete the key in case the domain fails, to avoid loops. It will be restored on login success.
  loginManager.forgetCurrentDomain();

  return router.createUrlTree([`/domains/${currentDomain}/`])
};

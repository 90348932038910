import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {InjectionToken, isDevMode, NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {BrowserModule} from '@angular/platform-browser';
import {RouteReuseStrategy} from '@angular/router';
import {ServiceWorkerModule} from '@angular/service-worker';
import {Capacitor} from "@capacitor/core";
import {IonicModule, IonicRouteStrategy} from '@ionic/angular';
import {EffectsModule} from '@ngrx/effects';
import {StoreRouterConnectingModule} from '@ngrx/router-store';
import {StoreModule} from '@ngrx/store';
import {StoreDevtoolsModule} from '@ngrx/store-devtools';
import {DomainResolver} from '@process-manager/pm-library';
import {OAuthModule} from 'angular-oauth2-oidc';
import {environment} from '../environments/environment';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {EmptyComponent} from './shared/components/empty/empty.component';
import {CredentialsInterceptor} from "./shared/interceptors/credentials.interceptor";
import {LanguageInterceptor} from './shared/interceptors/language.interceptor';
import {LocalLoginInterceptor} from "./shared/interceptors/local-login.interceptor";
import {MobileDomainResolver} from './shared/services/mobile-domain-resolver.service';
import {metaReducers, reducers} from './shared/state';
import {AuthEffects} from './shared/state/auth/auth.effects';
import {DomainEffects} from './shared/state/domain/domain.effects';
import {sideScrollAnimation} from './shared/utils/fixedAnimation';
import {TranslocoRootModule} from './transloco-root.module';


export const WINDOW = new InjectionToken<Window>('Global window object', {
  factory: () => window,
});

@NgModule({
  declarations: [AppComponent, EmptyComponent],
  imports: [BrowserModule, FormsModule, OAuthModule.forRoot({
    resourceServer: {
      allowedUrls: [environment.api],
      sendAccessToken: true
    }
  }), HttpClientModule,
    IonicModule.forRoot({
//      mode: 'ios',
      navAnimation: sideScrollAnimation,
    }), AppRoutingModule, ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: !Capacitor.isNativePlatform && !isDevMode(),
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000',
    }), StoreModule.forRoot(reducers, {metaReducers}),
    EffectsModule.forRoot([DomainEffects, AuthEffects]),
    StoreRouterConnectingModule.forRoot(),
    StoreDevtoolsModule.instrument({
      logOnly: environment.production,
      name: 'ProcessManager Mobile',
      connectInZone: true,
    }),
    TranslocoRootModule],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CredentialsInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LanguageInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LocalLoginInterceptor,
      multi: true
    },
    {
      provide: RouteReuseStrategy,
      useClass: IonicRouteStrategy,
    }, {
      provide: DomainResolver,
      useClass: MobileDomainResolver,
    }],
  bootstrap: [AppComponent],
})
export class AppModule {
}

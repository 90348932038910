import {Injectable} from '@angular/core';
import {getRouterSelectors} from "@ngrx/router-store";
import {Store} from '@ngrx/store';
import {DomainResolver} from '@process-manager/pm-library';
import {map, Observable, of} from 'rxjs';
import {environment} from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MobileDomainResolver implements DomainResolver {
  constructor(private store:Store) {
  }

  get api(): string {
        return environment.api
  }

  get domainName$(): Observable<string> {
    return this.store.select(getRouterSelectors().selectRouteParam('domain')).pipe(map((value) => value || ''));
  }

  get isOffline(): Observable<boolean> {
    return of(false);
  }
}

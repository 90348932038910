import {Component, NgZone, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {App, URLOpenListenerEvent} from '@capacitor/app';
import {getBrowserLang, LangDefinition, TranslocoService} from '@jsverse/transloco';
import {ThemeService} from './shared/services/theme.service';

@Component({
  selector: 'pm-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
  async ngOnInit() {
    this.themeService.initializeTheme();
  }

  constructor(private router: Router, private zone: NgZone, private transloco: TranslocoService,
              private themeService: ThemeService) {
    this.initializeApp();

    const isLangDefinition = (candidate: any): candidate is LangDefinition => !!candidate && candidate.hasOwnProperty('label');

    const browserLang = getBrowserLang() || '';
    const availableLanguage = this.transloco.getAvailableLangs().map(lang => isLangDefinition(lang) && lang.label || lang);

    this.transloco.setDefaultLang('en');

    if (availableLanguage.includes(browserLang)) {
      this.transloco.setActiveLang(browserLang);
    }
  }

  private initializeApp() {
    App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
      this.zone.run(() => {
        console.debug('Event url: ', event.url);
        const decodedUrl = new URL(event.url);
        this.router.navigateByUrl(decodedUrl.pathname + decodedUrl.search);
      });
    });
  }
}

type CachedStorageItem = Record<string, string> | null;

export class JsonBasedStorage implements Storage {
  private cachedStorageItem: CachedStorageItem = null;

  constructor(private storage: Storage, private storageKeyName: string) {
  }

  get length(): number {
    return !!this.item && Object.keys(this.item).length || 0;
  }

  clear(): void {
    this.cachedStorageItem = null;
    this.storage.removeItem(this.storageKeyName);
  }

  key(index: number): string | null {
    return this.item && Object.keys(this.item)[index] || null;
  }

  private get item() {
    if (!!this.cachedStorageItem) {
      return this.cachedStorageItem;
    }

    try {
      const rawItem = this.storage.getItem(this.storageKeyName);
      if (!rawItem) {
        return null;
      }
      const jsonItem = JSON.parse(rawItem) as CachedStorageItem;
      this.cachedStorageItem = jsonItem;
      return jsonItem;
    } catch (e) {
      console.warn('Json parsing failed', e);
    }
    return null;
  }

  private set item(newItem: CachedStorageItem) {
    this.cachedStorageItem = newItem;
    this.storage.setItem(this.storageKeyName, JSON.stringify(newItem));
  }

  getItem(key: string): string | null {
    const item = this.item;
    if (!item) {
      return null;
    }
    return item[key] || null;
  }

  removeItem(key: string): void {
    const item = this.item;
    if (!item) {
      return;
    }
    delete item[key];
    this.item = item;
  }

  setItem(key: string, data: string): void {
    let item = this.item;
    if (!item) {
      item = {};
    }
    item[key] = data;
    this.item = item;
  }
}

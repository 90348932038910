import {createActionGroup, emptyProps, props} from "@ngrx/store";

export type ChosenTheme = 'light'|'dark'|'system';

export const UserMenuActions = createActionGroup({
  source: 'Toolbar/UserMenu',
  events: {
    'Language Selected': props<{languageId: number}>(),
    'Theme Chosen': props<{theme: ChosenTheme}>(),
    'Logout': emptyProps()
  }
})

import {Injectable} from '@angular/core';
import {DomainStatus} from '@process-manager/pm-library';
import {firstValueFrom, merge} from 'rxjs';
import {LocalLoginService} from './local-login.service';
import {SettingsService} from './settings.service';
import {SsoLoginService} from './sso-login.service';

const CURRENT_DOMAIN_STORAGE_KEY = 'PMCurrentDomain';

@Injectable({
  providedIn: 'root',
})
export class LoginManagerService {
  hasValidTokens = (type: 'pm' | 'oidc') => {
    switch (type) {
      case 'pm':
        return this.localLoginService.hasValidTokens();
      case 'oidc':
        return this.ssoLoginService.hasValidTokens();
    }
  };

  public setDomain(domainStatus: DomainStatus) {
    if (domainStatus.type === 'pm') {
      this.localLoginService.setDomain(domainStatus.name);
    } else {
      this.ssoLoginService.configure(domainStatus);
    }
  }

  public getCurrentDomain() {
    return localStorage.getItem(CURRENT_DOMAIN_STORAGE_KEY);
  }

  public setCurrentDomain(name: string) {
    localStorage.setItem(CURRENT_DOMAIN_STORAGE_KEY, name);
  }

  public forgetCurrentDomain() {
    localStorage.removeItem(CURRENT_DOMAIN_STORAGE_KEY);
  }

  public clearDomain() {
    this.forgetCurrentDomain();
    this.localLoginService.setDomain();
    this.ssoLoginService.configure();
  }

  public getIdentityClaims(): Record<string, any> {
    if (this.ssoLoginService.hasValidTokens()) {
      return this.ssoLoginService.getIdentityClaims();
    } else if (this.localLoginService.hasValidTokens()) {
      return this.localLoginService.getClaims();
    }
    return {};
  }

  loadSettings(domain: string) {
    return this.settingsService.loadSettings(domain);
  }

  async loadSettingsOrStartSsoFlow(domain: string, route: string = '') {
    if (await this.ssoLoginService.startFlow(domain, route)) {
      return firstValueFrom(this.loadSettings(domain));
    }

    return null;
  }

  logOut() {
    this.ssoLoginService.logOut();
    this.localLoginService.logout().subscribe({
      next: () => console.debug('Logged out'),
      error: (err) => console.warn('Could not log out', err),
    });
  }

  userRefreshed$ = merge(this.ssoLoginService.userRefreshed$, this.localLoginService.userRefreshed$);

  constructor(private ssoLoginService: SsoLoginService, private localLoginService: LocalLoginService, private settingsService: SettingsService) {
  }
}

import {inject} from '@angular/core';
import {CanActivateFn, Router} from '@angular/router';
import {select, Store} from '@ngrx/store';
import {map} from 'rxjs';
import {filter} from 'rxjs/operators';
import {LoginGuardActions} from '../state/auth/auth.actions';
import {selectLoginState} from '../state/auth/auth.selectors';

export const LoginGuard: CanActivateFn = () => {
  const store = inject(Store);
  const router = inject(Router);

  store.dispatch(LoginGuardActions.protectedAreaEntered());

  return store.pipe(select(selectLoginState), filter(loadStatus => loadStatus !== 'waiting'), map(loadStatus => {
    if (loadStatus === 'ok') {
      return true;
    } else {
      console.warn('Login failure');
      // TODO: Add error message
      return router.parseUrl('/');
    }
  }));
};


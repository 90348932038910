import {createFeature, createReducer, createSelector, on} from '@ngrx/store';
import {SiteSettings} from '@process-manager/pm-library';
import {UserMenuActions} from '../../../tabs/components/tabs-toolbar/tabs-toolbar.actions';
import {AuthApiActions, AuthEffectsActions} from './auth.actions';
import {DomainGuardActions} from '../domain/domain.actions';
import {ProcessesTabEffectActions} from '../../../tabs/processes-tab/processes-tab.actions';

export const authFeatureKey = 'auth';

export interface State {
  token: string | null;
  user: Record<string, any> | null;
  userLanguageId: number | null;
  loginFailed: Error | null;
  siteSettings: SiteSettings | null;
}

export const initialState: State = {
  token: null,
  user: null,
  userLanguageId: null,
  loginFailed: null,
  siteSettings: null,
};

export const authReducer = createReducer(initialState,
  on(AuthApiActions.loginSuccess,
    (state, {userClaims, siteSettings}): State => ({
      ...state,
      loginFailed: null,
      user: userClaims,
      siteSettings,
    })),
  on(AuthApiActions.manualLoginSuccess,
    (state, {userClaims, siteSettings, token}): State => ({
      ...state,
      token,
      loginFailed: null,
      user: userClaims,
      siteSettings,
    })),
  on(AuthApiActions.loginFailure,
    (state, {error}): State => ({
      ...state,
      loginFailed: error || null,
    })),
  on(UserMenuActions.languageSelected, (state, {languageId}) => ({
    ...state,
    userLanguageId: languageId,
  })),
  on(AuthEffectsActions.userDataLoaded, (state, {userData}) => ({
    ...state,
    user: userData,
  })),
  on(DomainGuardActions.domainLeft,
    AuthEffectsActions.logoutComplete,
    ProcessesTabEffectActions.loadingErrorDismissedWithLogoutRequested,
    AuthEffectsActions.oidcAuthentication,
    AuthEffectsActions.manualAuthentication,
    (): State => initialState),
);

export const authFeature = createFeature({
  name: authFeatureKey,
  reducer: authReducer,
  extraSelectors: ({selectSiteSettings}) => ({
    selectRootNodeId: createSelector(selectSiteSettings, (settings) => settings?.rootId || null),
  }),
});

export const {
  selectRootNodeId,
  selectUserLanguageId,
  selectLoginFailed,
} = authFeature;

import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {SiteSettings} from '@process-manager/pm-library';
import * as immutable from 'immutable';
import {map, Observable} from 'rxjs';
import {environment} from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {
  private readonly jsonHeaderOptions = {
    headers: new HttpHeaders().set('Content-Type', 'application/json'),
    withCredentials: true
  };

  public loadSettings(domain: string): Observable<SiteSettings> {
    return this.http.get(environment.api + domain.toLowerCase() + '/settings', this.jsonHeaderOptions)
      .pipe(map(this.decodeSettings));
  }

  public decodeSettings(res: any): SiteSettings {
    const browserMap: immutable.Map<string, string> = immutable.Map(
      res.browsermap.map((map: any) => [map.folder[0], map.replace[0]]));
    let roles: immutable.Map<string, string[]> = immutable.Map();
    if (!!res.roles) {
      roles = immutable.Map(Object.entries(res.roles).map(([key, value]) => {
        if (!Array.isArray(value)) {
          value = [value];
        }
        return [key, value];
      }))
    }
    // noinspection JSUnresolvedReference
    return {
      showPath: !res.hidepath,
      showSiteMap: res.sitemapenabled,
      showFavorites: res.showFavorites,
      showNavMenu: res.navmenu,
      registerVisits: res.registerVisits,
      showStatistics: res.statistics,
      showLogoutLogbook: res.showLogoutLogbook || 'none',
      useServerMail: res.servermail,
      vendorFeatures: res.vendorFeatures,
      allowedTypes: res.allowedtype,
      languages: res.language,
      browserMap: browserMap,
      rootId: res.rootId,
      roles: roles,
      localGroupAdministration: res.localGroupAdministration,
      localUserAdministration: res.localUserAdministration,
      subscriptionState: res.subscriptionState || 'legacy',
      licencedTemplates: res.licencedTemplates || [],
      offlineEnabled: res.offlineEnabled || false
    }
  }

  constructor(private http: HttpClient) { }
}

import {RefresherEventDetail} from "@ionic/core/dist/types/components/refresher/refresher-interface";
import {createActionGroup, emptyProps, props} from '@ngrx/store';
import {Link} from "./shared/model/link";
import {SearchResult} from './shared/model/searchResult';

import {Tree} from './shared/model/tree';

export const ProcessesTabPageActions = createActionGroup({
  source: 'ProcessesTab/Page',
  events: {
    'Go Back Clicked': props<{nodeId: string}>(),
    'Link Clicked': props<{nodeId?: number, link: Link}>(),
    'Refresh Started': props<{refresherEventDetail: RefresherEventDetail}>(),
    'Dismiss Loading Error': emptyProps(),
    'Start Search': props<{searchString: string}>(),
    'Close Search': emptyProps()
  }
});

export const ProcessesTabApiActions = createActionGroup({
  source: 'ProcessesTab/API',
  events: {
    'Load Tree Success': props<{ nodeId: number, tree: Tree }>(),
    'Load Tree Failure': props<{ error: Error }>(),
    'Search Success': props<{ result: SearchResult }>(),
    'Search Failure': props<{ error: Error }>(),
  }
});

export const ProcessesS3ApiActions = createActionGroup({
  source: 'ProcessesTab/FileAPI',
  events: {
    'Open File Success': emptyProps(),
    'Open File Failed': props<{ error: Error }>(),
  }
})
export const ProcessesTabEffectActions = createActionGroup({
  source: 'ProcessesTab/Effects',
  events: {
    'Download And Open File': props<{label: string, url: string}>(),
    'Open External Link': props<{url: string}>(),
    'Open Nothing': emptyProps(),
    'Relevant Path Entered': emptyProps(),
    'Loading Error Dismissed': emptyProps(),
    'Loading Error Dismissed With Logout Requested': emptyProps(),
  },
});

import {createActionGroup, emptyProps, props} from '@ngrx/store';
import {SiteSettings} from '@process-manager/pm-library';

export const AuthApiActions = createActionGroup({
  source: 'Authentication/API',
  events: {
    'Login Success': props<{userClaims: Record<string, any>, siteSettings: SiteSettings}>(),
    'Manual Login Success': props<{userClaims: Record<string, any>, siteSettings: SiteSettings, token: string}>(),
    'Login Failure': props<{error?: Error, errorDetails?: string }>(),
    'Login Was Redirected': emptyProps(),
    'Start SSO Login': emptyProps(),
    'Settings Loaded': props<{siteSettings: SiteSettings}>()
  }
});

export const LoginGuardActions = createActionGroup({
  source: 'Authentication/Guard',
  events: {
    'Protected Area Entered': emptyProps(),
  }
});

export const LocalLoginInterceptorActions = createActionGroup({
  source: 'Authentication/LoginInterceptor',
  events: {
    'Refresh failed': props<{error?: Error}>(),
    'Refresh completed': emptyProps()
  }
})

export const AuthEffectsActions = createActionGroup({
  source: 'Authentication/Effects',
  events: {
    'Already Authenticated': emptyProps(),
    'User Data Loaded': props<{userData: Record<string, any> }>(),
    'Manual Authentication': emptyProps(),
    'Redirected for Manual Authentication': emptyProps(),
    'Oidc Authentication': emptyProps(),
    'Login Failure': props<{error?: Error, errorDetails?: string }>(),
    'Logout Complete': emptyProps(),
  }
});

import {NgModule} from '@angular/core';
import {provideTransloco, TranslocoModule} from '@jsverse/transloco';
import {environment} from '../environments/environment';
import {TranslocoHttpLoader} from './transloco-loader';

@NgModule({
  exports: [ TranslocoModule ],
  providers: [
      provideTransloco({
        config: {
          availableLangs: ['en', 'da'],
          defaultLang: 'en',
          fallbackLang: 'en',
          reRenderOnLangChange: true,
          prodMode: environment.production,
        },
        loader: TranslocoHttpLoader
      }),
  ],
})
export class TranslocoRootModule {}

import {createFeatureSelector, createSelector} from '@ngrx/store';
import * as fromDomain from './domain.reducer';

export const selectBaseDomainState = createFeatureSelector<fromDomain.State>(
  fromDomain.domainFeatureKey,
);
export const selectDomainStatus = createSelector(selectBaseDomainState, (domainState) => domainState.domainStatus);
export const selectDomainName = createSelector(selectDomainStatus, (domainStatus) => domainStatus?.name);
export const selectDomainType = createSelector(selectDomainStatus, (domainStatus) => domainStatus?.type);
export const selectDomainOidcConfig = createSelector(selectDomainStatus, (domainStatus) => domainStatus?.oidcConfig);
export const selectDomainLoadState = createSelector(selectBaseDomainState, (domainState) => {
  if(!!domainState.domainStatus) {
    return 'ok';
  } else if(domainState.loadError) {
    return 'error';
  } else {
    return 'waiting';
  }
})

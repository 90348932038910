import {inject} from '@angular/core';
import {CanActivateFn, Router} from '@angular/router';
import {select, Store} from '@ngrx/store';
import {map} from 'rxjs';
import {filter} from 'rxjs/operators';
import {DomainGuardActions} from '../state/domain/domain.actions';
import {selectDomainLoadState} from '../state/domain/domain.selectors';

export const DomainGuard: CanActivateFn = (route) => {
  const store = inject(Store);
  const router = inject(Router);
  const domain = route.paramMap.get('domain');
  if(!domain) {
    console.warn('No domain entered');
    return router.parseUrl(`/`);
  }

  store.dispatch(DomainGuardActions.domainEntered({domain}));
  return store.pipe(select(selectDomainLoadState), filter((status) => status !== 'waiting'), map(loadStatus => {
    if(loadStatus === 'ok') {
      return true;
    } else {
      console.warn('Missing or misconfigured domain?');
      return router.parseUrl(`/`);
    }
  }));
};

import {Component} from '@angular/core';

@Component({
  selector: 'pm-empty',
  templateUrl: './empty.component.html',
  styleUrls: ['./empty.component.scss'],
})
export class EmptyComponent {
  constructor() { }
}

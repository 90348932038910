import {NgModule} from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';
import {EmptyComponent} from './shared/components/empty/empty.component';
import {CallbackGuard} from './shared/guards/callback.guard';
import {PreselectedDomainGuard} from "./shared/guards/preselected-domain.guard";
import {DomainLeftGuard} from './shared/guards/domain-left.guard';
import {DomainGuard} from './shared/guards/domain.guard';
import {LoginGuard} from "./shared/guards/login.guard";

const routes: Routes = [
  {
    path: 'domains/:domain',
    canActivate: [DomainGuard],
    canDeactivate: [DomainLeftGuard],
    runGuardsAndResolvers: 'always',
    children: [
      {
        path: 'login',
        loadChildren: () => import('./login/manual-login/manual-login.module').then(m => m.LoginPageModule)
      },
      {
        path: 'tabs',
        canActivate: [LoginGuard],
        runGuardsAndResolvers: 'always',
        loadChildren: () => import('./tabs/tabs.module').then(m => m.TabsPageModule),
      },
      {
        path: '',
        redirectTo: 'tabs',
        pathMatch: 'full'
      }
    ]
  },
  {
    path: 'domains',
    loadChildren: () => import('./login/domain-chooser/domain-chooser.module').then(m => m.DomainChooserPageModule),
    canActivate: [PreselectedDomainGuard],
    pathMatch: 'full'
  },
  {
    path: '',
    redirectTo: '/domains',
    pathMatch: 'full'
  }, {
    path: 'callback',
    component: EmptyComponent,
    canActivate: [CallbackGuard],
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {preloadingStrategy: PreloadAllModules, paramsInheritanceStrategy: 'always'/*, enableTracing: true*/})
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {
}

import {createActionGroup, emptyProps, props} from '@ngrx/store';
import {DomainStatus} from '@process-manager/pm-library';
import {ExtendedDomainStatus} from '../../model/extendedDomainStatus';

export const DomainApiActions = createActionGroup({
  source: 'Domain API',
  events: {
    'Load Domain Status': props<{domain: string}>(),
    'Load Domain Status Success': props<{ domainStatus: DomainStatus | ExtendedDomainStatus }>(),
    'Load Domain Status Failure': props<{ error: Error}>(),
  },
});

export const DomainGuardActions = createActionGroup({
  source: 'Domain Guard',
  events: {
    'DomainEntered': props<{domain: string}>(),
    'DomainLeft': emptyProps(),
  },
});

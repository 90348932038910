import {
  HttpContextToken,
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Store} from '@ngrx/store';
import {BehaviorSubject, first, map, mergeMap, Observable} from 'rxjs';
import {catchError, filter, finalize, switchMap, take} from 'rxjs/operators';
import {environment} from '../../../environments/environment';
import {LocalLoginService} from '../services/local-login.service';
import {LocalLoginInterceptorActions} from '../state/auth/auth.actions';
import {selectDomainType} from '../state/domain/domain.selectors';
import {ReportableError} from '../model/ReportableError';

type RefreshStatus = 'idle' | 'refreshing' | 'error';

const REFRESH_ON_401 = new HttpContextToken<boolean>(() => false);

@Injectable()
export class LocalLoginInterceptor implements HttpInterceptor {
  readonly IGNORE_PATH = [
    (url: string) => url.endsWith('/security/session'),
    (url: string) => url.endsWith('/security/logout'),
    (url: string) => url.includes('/api/auth') && url.endsWith('/status'),
  ];

  constructor(private store: Store, private localLoginService: LocalLoginService) {
  }

  private refreshingStatus$ = new BehaviorSubject<RefreshStatus>('idle');

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return this.store.select(selectDomainType).pipe(
      first(),
      mergeMap(domainType => {
          if (this.needsToken(request, domainType)) {
            return this.useLocalToken(request, next);
          } else {
            return next.handle(request);
          }
        },
      ),
    );
  }

  private isIgnoredPath = (url: string) => {
    return this.IGNORE_PATH.some(checker => checker(url));
  };

  private needsToken(request: HttpRequest<unknown>, domainType: 'pm' | 'oidc' | undefined) {
    return domainType === 'pm' && request.url.startsWith(environment.api) && !this.isIgnoredPath(request.url);
  }

  private useLocalToken(request: HttpRequest<unknown>, next: HttpHandler) {
    if (!this.localLoginService.hasValidTokens()) {
      return this.doRefresh(next, request);
    }

    request = this.setTokenOnRequest(request);

    return next.handle(request).pipe(catchError((err: HttpErrorResponse) => {
      if (err && err.status === 401 && request.context.get(REFRESH_ON_401)) {
        return this.doRefresh(next, request);
      }
      throw err;
    }));
  }

  private setTokenOnRequest(request: HttpRequest<unknown>) {
    const token = this.localLoginService.getToken();
    if (token) {
      const header = 'Bearer ' + token;
      const headers = request.headers.set('Authorization', header);
      request = request.clone({headers});
    }
    return request;
  }

  private doRefresh(next: HttpHandler, request: HttpRequest<unknown>) {
    if (this.refreshingStatus$.value === 'idle') {
      this.refreshingStatus$.next('refreshing');
      return this.localLoginService.refresh().pipe(
        map((reply) => {
          if (!this.localLoginService.hasValidTokens()) {
            throw new Error('Tokens still invalid after refresh');
          }
          return reply;
        }),
        switchMap(() => {
          request.context.set(REFRESH_ON_401, false);
          return next.handle(this.setTokenOnRequest(request));
        }),
        catchError((err) => {
          this.refreshingStatus$.next('error'); // A single tick in error mode will send waiting responses
          this.store.dispatch(LocalLoginInterceptorActions.refreshFailed({
            error: new ReportableError(err, 'login.error.refresh-failed'),
          }));

          throw err;
        }),
        finalize(() => this.refreshingStatus$.next('idle')),
      );
    } else {
      return this.refreshingStatus$.pipe(
        filter((status) => status !== 'refreshing'),
        take(1),
        switchMap((status) => {
          if (status === 'error') {
            throw new Error('Could not refresh token, dropping request');
          }
          return next.handle(request);
        }));
    }
  }
}

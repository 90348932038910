import {isDevMode} from '@angular/core';
import {routerReducer, RouterState} from '@ngrx/router-store';
import {ActionReducerMap, createSelector, MetaReducer} from '@ngrx/store';
import {ReportableError} from '../model/ReportableError';
import {authReducer, selectLoginFailed, State as AuthState} from './auth/auth.reducer';
import {domainFeature, selectLoadError, State as DomainState} from './domain/domain.reducer';

export interface State {
  auth: AuthState
  router: RouterState
  domain: DomainState
}

export const reducers: ActionReducerMap<State> = {
  router: routerReducer,
  auth: authReducer,
  domain: domainFeature.reducer
};

export const metaReducers: MetaReducer<State>[] = isDevMode() ? [(reducer) =>
  (state, action) => {
/*    console.group?.(`NGRX Action: ${action.type}`);
    console.log(action);
    console.log(state);
    console.groupEnd?.();*/
    return reducer(state, action);
  }
] : [];

export const selectAuthOrDomainErrors = createSelector(selectLoadError, selectLoginFailed, (domainError, loginError) => {
  const error = domainError ?? loginError;
  if(!error) {
    return null;
  }
  if(error instanceof ReportableError && error.errorCode !== 'login.error.unknown') {
    return {
      error: error.errorCode
    }
  } else return {
    error: 'login.error.unknown',
    errorDetails: error?.message || undefined
  }
});

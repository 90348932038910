import {inject} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivateFn, Router} from '@angular/router';
import {Store} from '@ngrx/store';
import {firstValueFrom} from 'rxjs';
import {ReportableError} from '../model/ReportableError';
import {SettingsService} from '../services/settings.service';
import {SsoLoginService} from '../services/sso-login.service';
import {AuthApiActions} from '../state/auth/auth.actions';
import {DomainApiActions} from '../state/domain/domain.actions';

export const CallbackGuard: CanActivateFn = async (route: ActivatedRouteSnapshot) => {
  const authService = inject(SsoLoginService);
  const router = inject(Router);
  const store = inject(Store);
  const settingsService = inject(SettingsService);
  const queryParamMap = route.queryParamMap;

  if(!queryParamMap.has('state')) {
    store.dispatch(DomainApiActions.loadDomainStatusFailure({error: new ReportableError('No state in callback','login.error.sso.callback.incomplete')}));
    return false;
  }

  const domainAndReturnUrl = queryParamMap.get('state')?.split(';')?.pop() ?? '';

  if (!domainAndReturnUrl) {
    console.warn('Callback state without domain');
    store.dispatch(DomainApiActions.loadDomainStatusFailure({error: new ReportableError('No state in callback state', 'login.error.sso.callback.incomplete')}));
    return false;
  }

  const [domain, returnUrl] = decodeURIComponent(domainAndReturnUrl).split(';');

  console.debug('Domain:', domain, ', returnUrl:', returnUrl);
  try {
    const domainStatus = await firstValueFrom(authService.checkDomainAndConfigure(domain));

    if(!domainStatus) {
      console.warn('Misconfigured domain');
      store.dispatch(DomainApiActions.loadDomainStatusFailure({error: new ReportableError('Misconfigured domain','domain.error.misconfigured')}));
      return false;
    }

    if(domainStatus.type !== 'oidc') {
      console.warn('Not an OIDC domain in callback');
      store.dispatch(DomainApiActions.loadDomainStatusFailure({error: new ReportableError('Not an OIDC domain in callback', 'domain.error.tenant.oidc.wrong')}));
      return false;
    }
    console.debug('Domain loaded', JSON.stringify(domainStatus, null, 2));
    store.dispatch(DomainApiActions.loadDomainStatusSuccess({domainStatus}));

    history.replaceState(null, window.name, window.location.pathname + '?' + route.queryParamMap.keys.map(key => `${key}=${queryParamMap.get(key)}`).join('&'));

    try {
      await authService.completeFlow();
    } catch (err) {
      console.warn('Login failed');
      store.dispatch(AuthApiActions.loginFailure({error: new ReportableError(err as Error, 'login.error.unknown')}));
      return false;
    }

    try {
      const siteSettings = await firstValueFrom(settingsService.loadSettings(domain));
      console.debug(`Login success, dispatching action and redirecting to "${returnUrl}"`);
      store.dispatch(AuthApiActions.loginSuccess({siteSettings, userClaims: authService.getIdentityClaims()}));
      return router.parseUrl(returnUrl);
    } catch (err) {
      console.error('Login should have succeeded, but cannot load settings');
      store.dispatch(AuthApiActions.loginFailure({error: new ReportableError('Settings not loaded', 'login.error.unknown')}));
      return false;
    }
  } catch (err) {
    console.error('Something went wrong while configuring or fetching codes ', err);
    if(err instanceof Error) {
      store.dispatch(AuthApiActions.loginFailure({error: new ReportableError(err, 'login.error.unknown')}));
    } else {
      store.dispatch(AuthApiActions.loginFailure({error: new ReportableError('Unknown login error', 'login.error.unknown')}));
    }
    return false;
  }
};

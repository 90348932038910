import {inject} from '@angular/core';
import {CanDeactivateFn} from '@angular/router';
import {Store} from '@ngrx/store';
import {DomainGuardActions} from '../state/domain/domain.actions';

export const DomainLeftGuard: CanDeactivateFn<any> = (_comp, route, _state, newRoute) => {
  const store = inject(Store);

  const oldDomain = route.paramMap.get('domain');
  if(oldDomain !== null && !newRoute.url.includes(oldDomain)) {
    store.dispatch(DomainGuardActions.domainLeft());
  }

  return true;
};

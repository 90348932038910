import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Store} from '@ngrx/store';
import {first, mergeMap, Observable} from 'rxjs';
import {environment} from "../../../environments/environment";
import {selectUserLanguageId} from '../state/auth/auth.reducer';

@Injectable()
export class LanguageInterceptor implements HttpInterceptor {
  constructor(private store: Store) {
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return this.store.select(selectUserLanguageId).pipe(
      first(),
      mergeMap(languageId => {
        if (!!languageId && request.url.startsWith(environment.api)) {
          const httpRequest = request.clone({
            setParams: {
              'language': String(languageId)
            }
          });
          return next.handle(httpRequest);
        } else {
          return next.handle(request);
        }
      })
    )
  }
}

import {createFeature, createReducer, on} from '@ngrx/store';
import {ExtendedDomainStatus} from '../../model/extendedDomainStatus';
import {AuthEffectsActions} from "../auth/auth.actions";
import {DomainApiActions, DomainGuardActions} from './domain.actions';

export const domainFeatureKey = 'domain';

export interface State {
  domainStatus: ExtendedDomainStatus|null;
  loadError: Error|null,
}

export const initialState: State = {
  domainStatus: null,
  loadError: null,
};

export const reducer = createReducer(
  initialState,
  on(DomainApiActions.loadDomainStatus, DomainGuardActions.domainLeft, AuthEffectsActions.logoutComplete, (): State => initialState),
  on(DomainApiActions.loadDomainStatusSuccess, (state, {domainStatus}): State => ({...state, domainStatus})),
  on(DomainApiActions.loadDomainStatusFailure, (state, {error: loadError}): State => ({...state, loadError})),
);

export const domainFeature = createFeature({
  name: domainFeatureKey,
  reducer,
});

export const {
  selectLoadError
} = domainFeature

export class ReportableError extends Error {
  public get errorCode() {
    return this._errorCode
  }

  constructor(message: string, errorCode: string);
  constructor(originalError: Error, errorCode: string);
  constructor(originalErrorOrMessage: Error|string, private _errorCode: string) {
    super();
    if(originalErrorOrMessage instanceof Error) {
      super.message = originalErrorOrMessage.message;
      super.cause = originalErrorOrMessage;
    } else {
      super.message = originalErrorOrMessage;
    }
  }
}

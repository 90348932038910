import {getRouterSelectors} from '@ngrx/router-store';
import {createFeatureSelector, createSelector} from '@ngrx/store';
import * as fromAuth from './auth.reducer';
import {selectRootNodeId, selectUserLanguageId} from './auth.reducer';

export const selectAuthState = createFeatureSelector<fromAuth.State>(
  fromAuth.authFeatureKey,
);

export const selectCurrentUser = createSelector(selectAuthState, state => state.user || {});
export const selectCurrentUserFullName = createSelector(selectCurrentUser, user => {
  if(!!user['name']) {
    return user['name'];
  } else if(!!user['pm_first_name'] || !!user['pm_last_name']) {
    return [user['pm_first_name'] || '', user['pm_last_name'] || ''].join(' ').trim();
  } else {
    return '';
  }
});
export const selectCurrentUserEmail = createSelector(selectCurrentUser, user => user['email'] || user['pm_email'] || '');
export const selectLoginState = createSelector(selectAuthState, (authState) => {
  if (!!authState.user) {
    return 'ok';
  } else if (authState.loginFailed) {
    return 'error';
  } else {
    return 'waiting';
  }
});
export const selectSiteSettings = createSelector(selectAuthState, state => state.siteSettings);
export const selectSiteLanguages = createSelector(selectSiteSettings, settings => settings?.languages || [])
export const selectCurrentLanguage = createSelector(selectSiteLanguages, selectUserLanguageId, (languages, userLanguageId) => {
  return languages.find(lang => lang.id === userLanguageId) || languages.find(lang => lang.isDefault);
})

const routerSelectors = getRouterSelectors();
export const selectCurrentUrl = routerSelectors.selectUrl;

export const selectDomain = routerSelectors.selectRouteParam('domain');
export const selectNode = routerSelectors.selectRouteParam('nodeid');
export const selectDomainAndNode = createSelector(selectDomain, selectNode, (domain, nodeId) => ({
  domain: '' + domain,
  nodeId: +(nodeId ?? ''),
}));

export const selectDomainAndRoot = createSelector(routerSelectors.selectRouteParam('domain'), selectRootNodeId, (domain, rootNodeId) => ({
  domain: domain,
  nodeId: rootNodeId,
}));

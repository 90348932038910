import {Injectable, OnDestroy} from '@angular/core';
import {ChosenTheme} from "../../tabs/components/tabs-toolbar/tabs-toolbar.actions";

const THEME_MODE_STORAGE_KEY = 'ChosenThemeMode';

@Injectable({
  providedIn: 'root'
})
export class ThemeService implements OnDestroy {
  private _chosenTheme: ChosenTheme = 'system'
  private mediaMatcher = window.matchMedia('(prefers-color-scheme: dark)');

  private readLocalStorage(): ChosenTheme {
    return localStorage.getItem(THEME_MODE_STORAGE_KEY) as ChosenTheme || 'system';
  }

  private setLocalStorage(theme: ChosenTheme): void {
    localStorage.setItem(THEME_MODE_STORAGE_KEY, theme);
  }

  initializeTheme() {
    let theme = this.readLocalStorage();

    this.setTheme(theme);
  }

  setTheme(theme: ChosenTheme) {
    this._chosenTheme = theme;
    this.setLocalStorage(theme);
    this.toggleDarkTheme(this.effectiveTheme === 'dark');
  }

  get effectiveTheme(): Exclude<ChosenTheme, 'system'> {
    if(this._chosenTheme === 'system') {
      if(this.mediaMatcher.matches) {
        return 'dark';
      } else {
        return 'light';
      }
    } else {
      return this._chosenTheme;
    }
  }

  get chosenTheme(): ChosenTheme {
    return this._chosenTheme;
  }

  toggleDarkTheme(setDark: boolean) {
    document.documentElement.classList.toggle('ion-palette-dark', setDark);
  }

  private mediaMatchUpdate = (mediaQuery: MediaQueryListEvent) => {
    if(this._chosenTheme === 'system') {
      this.toggleDarkTheme(mediaQuery.matches);
    }
  }

  ngOnDestroy() {
    if(this.mediaMatcher) {
      this.mediaMatcher.removeEventListener('change', this.mediaMatchUpdate);
    }
  }

  constructor() {
    this.mediaMatcher.addEventListener('change', this.mediaMatchUpdate);
  }
}

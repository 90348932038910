import {JsonBasedStorage} from "./jsonBasedStorage";

const PERMANENT_KEYS = ['refreshToken', 'refresh_token']

export function createJsonBasedSelectiveStorage(key: string) {
  return new SelectiveStorage(new JsonBasedStorage(sessionStorage, key), new JsonBasedStorage(localStorage, key));
}

export class SelectiveStorage implements Storage {
  constructor(private temporaryStorage: Storage, private permanentStorage: Storage) {
  }

  get length() {
    return this.temporaryStorage.length + this.permanentStorage.length;
  }

  clear(): void {
    this.temporaryStorage.clear();
    this.permanentStorage.clear();
  }

  key(index: number): string | null {
    if (index < this.temporaryStorage.length) {
      return this.temporaryStorage.key(index);
    } else {
      index -= this.temporaryStorage.length;
      if (index < this.permanentStorage.length) {
        return this.permanentStorage.key(index);
      }
      return null;
    }
  }

  getStorage(key: string) {
    if (PERMANENT_KEYS.includes(key)) {
      return this.permanentStorage;
    } else {
      return this.temporaryStorage;
    }
  }

  getItem(key: string): string | null {
    return this.getStorage(key).getItem(key);
  }

  removeItem(key: string): void {
    this.getStorage(key).removeItem(key);
  }

  setItem(key: string, value: string): void {
    this.getStorage(key).setItem(key, value);
  }
}
